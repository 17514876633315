import { styled } from "@mui/system";

export const A = styled('a')({
    color: 'rgba(0,0,0,.9)',
    display: 'inline-block',
    paddingTop: '0.3125rem',
    paddingBottom: '0.3125rem',
    marginRight: '1rem',
    fontSize: '1.25rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap'
});