import { Box as MuiBox } from "@mui/material";
import { styled } from "@mui/system";

export const FormBody = styled(MuiBox)(({ theme }) =>
    theme.unstable_sx({
        paddingLeft: '1rem!important',
        paddingRight: '1rem!important',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: { xs: '540px', sm: '720px', lg: '960px', xl: '1140px' },
    })
);