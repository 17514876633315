import { SpeedDial as MuiSpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { color } from '@mui/system';
import useConfig from '../../Hooks/useConfig';

interface SpeedDialProps {
    actions: Array<{
        icon: React.ReactNode,
        name: string,
        click?(): any
    }>
}

const SpeedDial = (props: SpeedDialProps) => {
    const { actions } = props;
    const style = useConfig("style");
    return (
        <MuiSpeedDial
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16
            }}
            FabProps={{
                color: "primary"
            }}
            icon={
                <SpeedDialIcon
                    icon={<BuildOutlinedIcon />}
                    openIcon={<CloseOutlinedIcon />}
                />
            }
            ariaLabel={''}
        >
            {
                actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.click}
                        sx={{ color: style.text.primary }}
                    />
                ))
            }
        </MuiSpeedDial>
    )
}

export default SpeedDial;