//@ts-nocheck
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const LoginBody = styled(Box)({
    backgroundImage: `url(${process.env.PUBLIC_URL}${window.image.background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'bottom',
    backgroundSize: 'contain',
    height: '100vh'
});

export default LoginBody;