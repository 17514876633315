import { useEffect, useState } from 'react';
import LoginBody from '../../Component/LoginBody';
import { LoginBox } from '../../Component/LoginBox';
import { NavBar } from '../../Component/NavBar';
import { A } from '../../Component/A';
import { Image } from "../../Component/Image";
import { H1 } from '../../Component/H1';
import { LoginForm } from '../../Forms/LoginForm';
import useIosInstallPrompt from '../../Hooks/useIosInstallPrompt';
import { InstallPWA } from '../../Component/InstallPWA';
import useConfig from '../../Hooks/useConfig';
import axios from 'axios';

const LoginPage: React.FC = () => {
    const [open, setOpen] = useState(true);
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const config = useConfig("image");
    const api = useConfig('api');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const get_session = async () => {
            const url = api + "login/get_session";
            const response = await axios({
                method: 'post',
                url: url,
                withCredentials: true
            })
            if (response.data["user_name"])
                window.location.href = "/"
        }
        get_session();
        setOpen(iosInstallPrompt);
    }, []);

    return (
        <LoginBody>
            <NavBar>
                <LoginBox>
                    <A href='/'>
                        <Image src={process.env.PUBLIC_URL + config.logo} />
                    </A>
                </LoginBox>
            </NavBar>
            <H1>臨床教育 E-Portfolio 2.0版</H1>
            <LoginForm />
            <InstallPWA open={open} handleClose={handleClose} handleIOSInstallDeclined={handleIOSInstallDeclined} />
        </LoginBody>
    )
}

export default LoginPage;