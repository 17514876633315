// @ts-nocheck
import { FormBody } from "../FormBody";
import { Snackbar } from '@mui/material';
import { Alert } from '../../Component/Alert';
import { useEffect, useState } from "react";
import { PasswordInput, UserInput } from "../FormInput/FormInput.stories";
import { LoginButton } from "../../Component/Button/Button.stories";
import axios from 'axios';
import useConfig from "../../Hooks/useConfig";

export const LoginForm: React.FC = () => {
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = useState<"success" | "error">("error");
    const [alertMessages, setAlertMessages] = useState<string>("");
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const api = useConfig('api');

    useEffect(() => {
        if (userName.length === 0 || password.length === 0)
            setCanSubmit(false);
        else
            setCanSubmit(true);
    }, [userName, password])

    const handleClickLogin = async () => {
        if (!canSubmit) {
            return null;
        }
        try {
            const url = api + "login/pwa_login";
            const formData = new FormData();
            formData.append("account", userName);
            formData.append("password", password);
            formData.append("no_redirect", '1');
            const response = await axios({
                method: 'post',
                url: url,
                data: formData,
                withCredentials: true
            })
            if (response.data.status) {
                window.location.href = '/';
            }
            else {
                setAlertSeverity("error");
                setAlertMessages(response.data.message);
                setAlertOpen(true);
            }
        }
        catch (err) {
            setAlertSeverity("error");
            setAlertMessages(err.response.data.message);
            setAlertOpen(true);
        }
    }

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    }

    return (
        <FormBody>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessages}
                </Alert>
            </Snackbar>
            <UserInput
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
            />
            <PasswordInput
                value={password}
                onChange={(event) => setPassword(event.target.value)}
            />
            <LoginButton
                disabled={!canSubmit}
                onClick={() => handleClickLogin()}
            >
                登入
            </LoginButton>
        </FormBody>
    )
}