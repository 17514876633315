//@ts-nocheck
import { styled } from "@mui/system";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemButton, ListItemIcon, Dialog as MuiDialog, TextField, Select, MenuItem, Autocomplete, Chip, Typography, Snackbar } from "@mui/material";
import { ChatDialogAppBar, ChatListDialogAppBar, SettingDialogAppBar } from "../AppBar";
import { TransitionProps } from "@mui/material/transitions";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Slide from "@mui/material/Slide";
import ListItemText from "@mui/material/ListItemText";
import ListItem from '@mui/material/ListItem';
import List from "@mui/material/List";
import { LogoutIcon } from "../../Icon/LogoutIcon";
import { ArticleIcon } from "../../Icon/ArticleIcon";
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from "axios";
import useConfig from "../../Hooks/useConfig";
import { CalendarMonthIcon } from "../../Icon/CalendarMonthIcon";
import { ChatList, MessageList, Input, Button as RCEButton } from "react-chat-elements";
import { Alert } from '../../Component/Alert';
import SendIcon from '@mui/icons-material/Send';
import GroupsIcon from '@mui/icons-material/Groups';
import "react-chat-elements/dist/main.css"
import './index.css'

export const Dialog = styled(MuiDialog)(({ theme }) =>
    theme.unstable_sx({
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
    open: boolean,
    handleDialogClose?(): any,
    messageGroupList?: any,
    getGroupList?(): any
}

interface FilterDialogProps {
    open: boolean,
    handleDialogClose(): any,
    fliterSearch(filterPattern: string): any
}

export const LayoutSettingDialog = (props: DialogProps) => {
    const { open, handleDialogClose, ...other } = props;
    const [identity, setIdentity] = useState([]);
    const [selectIdentity, setSelectIdentity] = useState();
    const [group, setGroup] = useState([]);
    const [selectGroup, setSelectGroup] = useState();
    const api = useConfig('api');

    const getData = async () => {
        const url = `${api}admin/admin/get_user_all_identity`;
        const response = await axios({
            method: 'get',
            url: url,
            withCredentials: true
        });
        setIdentity(response.data.data.user_all_identity)
        const url_2 = `${api}admin/admin/get_user_now_identity`;
        const response_2 = await axios({
            method: 'get',
            url: url_2,
            withCredentials: true
        });
        setSelectIdentity(response_2.data.data.user_now_identity.identity_id);
        const url_3 = `${api}admin/admin/get_user_all_group`;
        const response_3 = await axios({
            method: 'get',
            url: url_3,
            withCredentials: true
        });
        setGroup(response_3.data.data.group)
        const url_4 = `${api}admin/admin/get_user_now_group`;
        const response_4 = await axios({
            method: 'get',
            url: url_4,
            withCredentials: true
        });
        setSelectGroup(response_4.data.data.group.group_id);
    }


    const handleLogoutOnClick = async () => {
        const url = api + "login/pwa_logout";
        await axios({
            method: 'post',
            url: url,
            withCredentials: true
        })
        window.location.href = '/login';
    }


    useEffect(() => {
        getData();
    }, [])

    const handleIdentityonChange = async (selectIdentity) => {
        const formData = new FormData();
        formData.append("is_pwa", 1);
        formData.append("identity_id", selectIdentity);
        const url = api + "admin/admin/ajax_change_user_identity";
        await axios({
            method: 'post',
            data: formData,
            url: url,
            withCredentials: true
        })
        setSelectIdentity(selectIdentity)
        window.location.reload();
    }

    const handleGrouponChange = async (selectGroup) => {
        const formData = new FormData();
        formData.append("is_pwa", 1);
        formData.append("group_id", selectGroup);
        const url = api + "admin/admin/ajax_change_user_group";
        await axios({
            method: 'post',
            data: formData,
            url: url,
            withCredentials: true
        })
        setSelectGroup(selectGroup)
        window.location.reload();
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
        >
            <SettingDialogAppBar handleDialogClose={handleDialogClose} />
            <List>
                <ListItemButton
                    onClick={() => window.location.href = "/pending"}
                >
                    <ListItemIcon>
                        <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="表單待辦" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => window.location.href = "/calendar"}
                >
                    <ListItemIcon>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="行事曆" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => window.location.href = "/todycourse"}
                >
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="今日課程" />
                </ListItemButton>
                <ListItemButton>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="權限" />
                    <Select
                        value={selectIdentity}
                        label="權限"
                        onChange={(e) => { handleIdentityonChange(e.target.value); }}
                    >
                        {
                            identity.map((item) => (
                                <MenuItem value={item.identity_id}>{item.describe}</MenuItem>
                            ))
                        }
                    </Select>
                </ListItemButton>
                <ListItemButton>
                    <ListItemIcon>
                        <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText primary="單位" />
                    <Select
                        value={selectGroup}
                        label="單位"
                        onChange={(e) => { handleGrouponChange(e.target.value); }}
                    >
                        {
                            group.map((item) => (
                                <MenuItem value={item.group_id}>{item.group_name}</MenuItem>
                            ))
                        }
                    </Select>
                </ListItemButton>
                <ListItemButton
                    onClick={() => handleLogoutOnClick()}
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="登出" />
                </ListItemButton>
            </List>
        </Dialog>
    )
};

export const NotLoginDialog = (props: DialogProps) => {
    const { open, ...other } = props;

    const handleGobackLoginPage = () => {
        window.location.href = "/login";
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogContent>
                您尚未登入，請返回登入頁面。
            </DialogContent>
            <DialogActions>
                <Button onClick={handleGobackLoginPage} autoFocus>
                    返回登入頁面
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export const GetPermissionDialog = (props) => {
    const { open, setGetPermissionDialogOpen, ...other } = props;

    const getPermission = async () => {
        if ('Notification' in window) {
            console.log('Notification.permission:' + Notification.permission);
            if (Notification.permission != 'granted') {
                console.log('Ask user permission')
                const permission = await Notification.requestPermission();
                setGetPermissionDialogOpen(false)
            }
        }
        else
            setGetPermissionDialogOpen(false)
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogContent>
                請開啟通知，以便接收最新消息。
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setGetPermissionDialogOpen(false)}} >
                    暫時不要
                </Button>
                <Button onClick={getPermission} autoFocus>
                    開啟通知
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export const FilterDialog = (props: FilterDialogProps) => {
    const { open, handleDialogClose, fliterSearch, ...other } = props;
    const [filterPattern, setFilterPattern] = useState("");

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
        >
            <DialogTitle>表單篩選</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    輸入關鍵字篩選表單。
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="關鍵字"
                    label="關鍵字"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={filterPattern}
                    onChange={(event) => setFilterPattern(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        fliterSearch("");
                        handleDialogClose();
                    }}
                >
                    清除
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        fliterSearch(filterPattern);
                        handleDialogClose();
                    }}
                >
                    篩選
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export const ChatDialog = (props: DialogProps) => {
    const { open, handleDialogClose, messageGroupList, getGroupList, ...other } = props;
    const [chatOpen, setChatOpen] = useState(false);
    const [addGroupOpen, setAddGroupOpen] = useState(false);
    const [chatGroup, setChatGroup] = useState(null);
    const [chatMessageList, setChatMessageList] = useState([]);
    const api = useConfig('api');

    const getChatMessages = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_load_chat_messages";
        formData.append("is_pwa", 1);
        formData.append("id", chatGroup.id);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        let chatList = [];
        if (response.data.data.message) {
            response.data.data.message.map((item) => {
                chatList.push({
                    position: item.is_self_send ? "right" : "left",
                    type: "text",
                    title: item.send_user_name,
                    text: item.message
                })
            })
            setChatMessageList(chatList)
        }
    }

    const handleChatDialogClose = () => {
        getGroupList()
        setChatOpen(false)
    }

    const handleAddGroupDialogOpen = () => {
        setAddGroupOpen(true)
    }

    const handleAddGroupDialogClose = () => {
        getGroupList()
        setAddGroupOpen(false)
    }

    const setlastSeen = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_update_last_seen";
        formData.append("is_pwa", 1);
        formData.append("id", chatGroup.id);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
    }

    useEffect(() => {
        getGroupList()
    }, [])

    useEffect(() => {
        if (chatGroup) {
            getChatMessages();
            setlastSeen();
            setChatOpen(true);
        }
    }, [chatGroup])

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
        >
            <ChatListDialogAppBar handleDialogClose={handleDialogClose} handleAddGroupDialogOpen={handleAddGroupDialogOpen} />
            {
                messageGroupList.length === 0 ?
                    <Box sx={{ marginTop: '40vh', textAlign: 'center' }}>
                        <Typography variant="body2" gutterBottom >無任何聊天群組</Typography>
                    </Box>
                    :
                    <ChatList
                        className='chat-list'
                        dataSource={messageGroupList}
                        onClick={(chat) => { setChatGroup(chatGroup => ({ ...chatGroup, ...chat })) }}
                    />
            }
            <AddGroupDialog open={addGroupOpen} handleAddGroupDialogClose={handleAddGroupDialogClose} />
            {
                chatGroup ?
                    <MessageListDialog
                        chatOpen={chatOpen}
                        handleChatDialogClose={handleChatDialogClose}
                        chatGroup={chatGroup}
                        chatMessageList={chatMessageList}
                        getChatMessages={getChatMessages}
                    />
                    : null
            }
        </Dialog>
    )
}

export const MessageListDialog = (props) => {
    const { chatOpen, handleChatDialogClose, chatGroup, chatMessageList, getChatMessages } = props;
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [groupUser, setGroupUser] = useState([]);
    const messageListReferance = useRef()
    const inputReferance = useRef()
    let clearRef = () => { }
    const forceUpdate = useForceUpdate()
    const api = useConfig('api');

    function useForceUpdate() {
        const [value, setValue] = useState(0)
        return () => setValue(() => value + 1)
    }

    const getGroupSettings = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/open_settings_modal";
        formData.append("is_pwa", 1);
        formData.append("id", chatGroup.id);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        setGroupUser(response.data.data.member)
    }

    const handleOnClick = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_send_message";
        formData.append("is_pwa", 1);
        formData.append("id", chatGroup.id);
        formData.append("input_message", inputReferance.current.value);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        inputReferance.current.value = ''
        getChatMessages();
        clearRef()
        forceUpdate()
    }

    const handleAddUserDialogOpen = () => {
        setAddUserOpen(true)
    }

    const handleAddUserDialogClose = () => {
        setAddUserOpen(false)
    }

    useEffect(() => {
        if (chatGroup)
            getGroupSettings();
    }, [chatGroup])

    return (
        <Dialog
            fullScreen
            open={chatOpen}
            onClose={handleChatDialogClose}
            TransitionComponent={Transition}
        >
            <ChatDialogAppBar handleAddUserDialogOpen={handleAddUserDialogOpen} handleDialogClose={handleChatDialogClose} groupName={chatGroup.title} />
            <MessageList
                className='message-list'
                lockable={true}
                downButton={true}
                sendMessagePreview={true}
                referance={messageListReferance}
                dataSource={chatMessageList}
            />
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    margin: '0 auto 1rem auto',
                    width: '90%'
                }}
            >
                <Input
                    className='rce-example-input'
                    placeholder='Write your message here.'
                    defaultValue=''
                    referance={inputReferance}
                    maxHeight={50}
                    rightButtons={
                        <RCEButton
                            icon={{
                                float: 'left',
                                size: 30,
                                component: <SendIcon />
                            }}
                            onClick={handleOnClick}
                        />
                    }
                    inputStyle={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '30px' }}
                />
            </div>
            <AddUserDialog chatGroup={chatGroup} groupUser={groupUser} open={addUserOpen} handleAddUserDialogClose={handleAddUserDialogClose} />
        </Dialog>
    )
}

export const AddGroupDialog = (props) => {
    const { open, handleAddGroupDialogClose, ...other } = props;
    const [groupName, setGroupName] = useState("");
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState([]);

    const getUserInfo = async (value) => {
        const formData = new FormData();
        const url = api + "admin/system/user_picker/ajax_search_account_info";
        formData.append("is_pwa", 1);
        formData.append("account", value);
        formData.append("maxRows", 12);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        setUserList(response.data.data.account_data)
    }

    const handleOnClick = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_save_message_group";

        formData.append("is_pwa", 1);
        formData.append("message_group_id", '');
        formData.append("message_group_name", groupName);
        for (let i = 0; i < user.length; i++) {
            formData.append("member_user_id[]", user[i].user_id);
        }
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleAddGroupDialogClose}
            TransitionComponent={Transition}
            fullWidth
        >
            <DialogTitle>建立群組</DialogTitle>
            <DialogContent>
                <TextField
                    label="群組名稱"
                    fullWidth
                    value={groupName}
                    onChange={(e) => { setGroupName(e.target.value) }}
                    sx={{ mt: 1, mb: 1 }}
                />
                <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    fullWidth
                    filterOptions={(x) => x}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="找不到搜尋的人員"
                    options={userList}
                    value={user}
                    onChange={(event, newValue) => {
                        setUser(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        getUserInfo(newInputValue);
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} label="請輸入人事號(可輸入姓名)" />}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {option.account} {option.name} {option.group_name}
                                </Typography>
                            </li>
                        )

                    }}
                    sx={{ mt: 1, mb: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        setGroupName('')
                        setUserList([])
                        setUser([])
                        handleAddGroupDialogClose();
                    }}
                >
                    取消
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        setGroupName('')
                        setUserList([])
                        setUser([])
                        handleOnClick();
                        handleAddGroupDialogClose();
                    }}
                >
                    建立群組
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export const AddUserDialog = (props) => {
    const { chatGroup, groupUser, open, handleAddUserDialogClose, ...other } = props;
    const [groupName, setGroupName] = useState("");
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState([]);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessages, setAlertMessages] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const getUserInfo = async (value) => {
        const formData = new FormData();
        const url = api + "admin/system/user_picker/ajax_search_account_info";
        formData.append("is_pwa", 1);
        formData.append("account", value);
        formData.append("maxRows", 12);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        setUserList(response.data.data.account_data)
    }

    const groupUserInit = async () => {
        let userInit = [];
        for (let i = 0; i < groupUser.length; i++) {
            const formData = new FormData();
            const url = api + "admin/system/user_picker/ajax_search_account_info";
            formData.append("is_pwa", 1);
            formData.append("account", groupUser[i].info_text.split(' ')[0]);
            formData.append("maxRows", 12);
            const response = await axios({
                method: 'post',
                url: url,
                data: formData,
                withCredentials: true
            })
            userInit.push(response.data.data.account_data[0])
        }
        setUser(userInit)
    }

    const handleOnClick = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_save_message_group";

        formData.append("is_pwa", 1);
        formData.append("message_group_id", chatGroup.id);
        formData.append("message_group_name", groupName);
        for (let i = 0; i < user.length; i++) {
            formData.append("member_user_id[]", user[i].user_id);
        }
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        if (response.data.status) {
            setAlertSeverity("success");
            setAlertMessages(response.data.message);
            setAlertOpen(true);
        }
        else {
            setAlertSeverity("error");
            setAlertMessages(response.data.message);
            setAlertOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    }

    useEffect(() => {
        groupUserInit()
    }, [groupUser])

    return (
        <Dialog
            open={open}
            onClose={handleAddUserDialogClose}
            TransitionComponent={Transition}
            fullWidth
        >
            <DialogTitle>新增成員</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    fullWidth
                    filterOptions={(x) => x}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="找不到搜尋的人員"
                    options={userList}
                    value={user}
                    onChange={(event, newValue) => {
                        setUser(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        getUserInfo(newInputValue);
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} label="請輸入人事號(可輸入姓名)" />}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {option.account} {option.name} {option.group_name}
                                </Typography>
                            </li>
                        )

                    }}
                    sx={{ mt: 1, mb: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        setGroupName('')
                        setUserList([])
                        setUser([])
                        handleAddGroupDialogClose();
                    }}
                >
                    取消
                </Button>
                <Button
                    color="success"
                    onClick={() => {
                        setGroupName('')
                        setUserList([])
                        setUser([])
                        handleOnClick();
                        handleAddGroupDialogClose();
                    }}
                >
                    新增成員
                </Button>
            </DialogActions>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} sx={{ whiteSpace: 'pre-line' }}>
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessages}
                </Alert>
            </Snackbar>

        </Dialog >
    )
}

export const NotifyListDialog = (props) => {
    const { notifyOpen, handleNotifyDialogClose, notifyList, getNotifyList } = props;

    const readMesage = async (id) => {
        const formData = new FormData();
        const url = api + "admin/system/notify/ajax_action_notify";
        formData.append("is_pwa", 1);
        formData.append("notify_id", id);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
    }

    const readAllMesage = async (id) => {
        const formData = new FormData();
        const url = api + "admin/system/notify/ajax_read_notify_by_target_user_id ";
        formData.append("is_pwa", 1);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
    }

    useEffect(() => {
        if (notifyOpen) {
            readAllMesage();
            getNotifyList();
        }
    }, [notifyOpen])
    return (
        <Dialog
            fullScreen
            open={notifyOpen}
            onClose={handleNotifyDialogClose}
            TransitionComponent={Transition}
        >
            <SettingDialogAppBar handleDialogClose={handleNotifyDialogClose} />
            <List>
                {
                    notifyList.map((item) => (
                        <ListItem
                            sx={{ backgroundColor: item.is_action === '0' ? 'yellow' : 'white' }}
                            onClick={() => {
                                handleNotifyDialogClose();
                                readMesage(item.notify_id);
                                window.location.href = `/pending/${item.event_id}`
                            }}
                        >
                            <ListItemText primary={item.notify_content} secondary={item.send_time} />
                        </ListItem>
                    ))
                }
            </List>
        </Dialog>
    )
}