import { Box, CircularProgress, Container, Snackbar, Toolbar, Typography, Grid, Stack, Chip, TextField, Autocomplete, RadioGroup, FormControlLabel, Radio, MenuItem, Select, FormControl } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InfoCard from "./InfoCard";
import Questions from './Questions';
import { Alert } from '../../Component/Alert';
import { Accordion, AccordionDetails, AccordionSummary } from "../../Component/Accordion";
import { useForm } from 'react-hook-form';
import { Button } from "../../Component/Button";
import { Card, CardContent, CardHeader, FeedbackCard, FeedbackCardContent, FeedbackCardHeader } from "../../Component/Card";
import useConfig from "../../Hooks/useConfig";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const PendingFormPage = () => {
    const { aiID } = useParams();
    const [loading, setLoading] = useState(false);
    const [formID, setFormID] = useState("");
    const [formName, setFormName] = useState("");
    const [info, setInfo] = useState();
    const [nextValidProcessAccount, setNextValidProcessAccount] = useState(false);
    const [nextValidProcess, setNextValidProcess] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [history, setHistory] = useState([]);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessages, setAlertMessages] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [nextUserList, setNextUserList] = useState([]);
    const [nextUser, setNextUser] = useState(null);
    const [havePreviousProcess, setHavePreviousProcess] = useState(false);
    const [previousProcess, setPreviousProcess] = useState(null);
    const [sign, setSign] = useState({
        state: [],
        opinion: ''
    });
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const api = useConfig('api');


    const getData = async () => {
        const url = `${api}admin/common/form/write/${aiID}/workflow?is_pwa=1`;
        setLoading(true);
        try {
            const response = await axios({
                method: 'get',
                url: url,
                withCredentials: true
            });
            setFormID(response.data.data.form_id)
            setFormName(response.data.data.form_name);
            setInfo(response.data.data.info);
            setNextValidProcessAccount(response.data.data.set_next_valid_process_account);
            if (response.data.data.set_next_valid_process_account) {
                setNextValidProcess(response.data.data.next_valid_process);
            }
            setQuestions(response.data.data.question);
            setFeedback(response.data.data.feedback);
            setHistory(response.data.data.history);
            setSign(response.data.data.sign);
            setHavePreviousProcess(response.data.data.have_previous_process);
            if (response.data.data.have_previous_process) {
                setPreviousProcess(response.data.data.previous_process);
            }
            setLoading(false);
        }
        catch {
            setAlertSeverity("error");
            setAlertMessages("伺服器發生錯誤，請稍後再試。");
            setAlertOpen(true);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    }

    const questionsList = (question) => {
        switch (question.type) {
            case 1:
                return (
                    <Card>
                        <CardHeader
                            title={question.content}
                        />
                    </Card>
                )
            case 2:
                return (
                    <Card>
                        <CardHeader
                            title={question.content}
                        />
                    </Card>
                )
            case 3:
                return (
                    <Card>
                        <CardHeader
                            title={question.content}
                        />
                    </Card>
                )
            case 5:
                return (
                    <Accordion>
                        <AccordionSummary>
                            <Typography>{question.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Questions ai_id={aiID} questions={question.questions} control={control} register={register} errors={errors} />
                        </AccordionDetails>
                    </Accordion>
                )
            default:
                break;
        }
    }

    const createFormData = (data) => {
        const formData = new FormData();
        formData.append("is_pwa", 1);
        formData.append("form_id", formID);
        formData.append("ai_id", aiID);
        formData.append("sign_state", null);
        formData.append("opinion", null);
        for (let i = 1; i < data.form_question.length; i++) {
            if (data.form_question[i]) {
                for (let j = 1; j < data.form_question[i].length; j++) {
                    if (Array.isArray(data.form_question[i][j])) {
                        for (let index = 0; index < data.form_question[i][j].length; index++) {
                            if (data.form_question[i][j][index] !== undefined && data.form_question[i][j][index] !== null)
                                formData.append(`form_question[${i}][${j}][]`, data.form_question[i][j][index]);
                            else
                                formData.append(`form_question[${i}][${j}][]`, '');
                        }
                    }
                    else if (
                        typeof data.form_question[i][j] === 'object' &&
                        !Array.isArray(data.form_question[i][j]) &&
                        data.form_question[i][j] !== null
                    ) {
                        if (data.form_question[i][j]['date']) {
                            if (data.form_question[i][j]['date'] !== undefined && data.form_question[i][j]['date'] !== null)
                                formData.append(`form_question[${i}][${j}][date]`, data.form_question[i][j]['date']);
                            else
                                formData.append(`form_question[${i}][${j}][date]`, '');
                        }
                        else {
                            if (data.form_question[i][j]['ans'] !== undefined && data.form_question[i][j]['ans'] !== null)
                                formData.append(`form_question[${i}][${j}][]`, data.form_question[i][j]['ans']);
                            else
                                formData.append(`form_question[${i}][${j}][]`, '');
                            if (data.form_question[i][j]['text']) {
                                for (let k = 1; k < data.form_question[i][j]['text'].length; k++)
                                    if (data.form_question[i][j]['text'][k] !== undefined && data.form_question[i][j]['text'][k] !== null)
                                        formData.append(`form_question[${i}][${j}][text][${k}]`, data.form_question[i][j]['text'][k]);
                                    else
                                        formData.append(`form_question[${i}][${j}][text][${k}]`, '');
                            }
                        }
                    }
                    else {
                        if (data.form_question[i][j] !== undefined && data.form_question[i][j] !== null)
                            formData.append(`form_question[${i}][${j}]`, data.form_question[i][j]);
                        else
                            formData.append(`form_question[${i}][${j}]`, '');
                    }
                }
            }
        }
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].questions) {
                for (let j = 0; j < questions[i].questions.length; j++) {
                    if (questions[i].questions[j].ques_path) {
                        formData.append(`ques_path_ques_id[${questions[i].questions[j].ques_path.split('-')[0]}][${questions[i].questions[j].ques_path.split('-')[1]}]`, questions[i].questions[j].ques_id);
                    }
                }
            }

        }
        if (nextValidProcessAccount) {
            if (nextUser) {
                formData.append("next_valid_process_id", nextValidProcess.process_id);
                formData.append("next_valid_process_account", `${nextUser.name} (${nextUser.account})`);
                formData.append("next_process_account", nextUser.account);
            }
        }
        if (data.sign_state) {
            formData.append('sign_state', data.sign_state)
        }
        if (data.sign_state_back_to_process_id) {
            formData.append('back_to_process_id', data.sign_state_back_to_process_id)
        }
        if (data.sign_opinion) {
            formData.append('opinion', data.sign_opinion)
        }
        return formData;
    }

    const tmpSaveOnClick = async (data) => {
        const url = api + "admin/common/form/user_tmp_save";
        const formData = createFormData(data);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true
        })
        if (response.data.status) {
            setAlertSeverity("success");
            setAlertMessages("暫存成功！");
            setAlertOpen(true);
        }
        else {
            setAlertSeverity("error");
            setAlertMessages(response.data.message);
            setAlertOpen(true);
        }
    }


    const submitOnClick = async (data) => {
        const url = api + "admin/common/form/finish";
        const formData = createFormData(data);
        if (nextValidProcessAccount && !nextUser) {
            setAlertSeverity("error");
            setAlertMessages("未設定簽核者無法送出");
            setAlertOpen(true);
        }
        else {
            const response = await axios({
                method: 'post',
                url: url,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true
            })
            if (response.data.status) {
                window.location.href = '/pending';
            }
            else {
                setAlertSeverity("error");
                setAlertMessages(response.data.message);
                setAlertOpen(true);
            }
        }
    }

    const getUserInfo = async (value) => {
        const formData = new FormData();
        const url = api + "admin/system/user_picker/ajax_search_account_info";
        formData.append("is_pwa", 1);
        formData.append("account", value);
        formData.append("maxRows", 12);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        setNextUserList(response.data.data.account_data)
    }

    return (
        <Container fixed>
            <Toolbar sx={{ padding: '0.5rem 0px' }} />
            {
                loading ?
                    <Box sx={{ textAlign: "center", paddingTop: '1rem' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <InfoCard formID={formID} formName={formName} info={info} />

                        {
                            nextValidProcessAccount ?
                                <Box sx={{ backgroundColor: "#fbffa0", p: 2 }}>
                                    <Typography variant="h6" gutterBottom >{`※ 請設定 (${nextValidProcess.sequence}.${nextValidProcess.type_name}:${nextValidProcess.user_role_name}) 的簽核者，填入人事號：`}</Typography>
                                    <Autocomplete
                                        id="get-next-user"
                                        freeSolo
                                        disableClearable
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : `${option.account} ${option.name} ${option.group_name}`
                                        }
                                        filterOptions={(x) => x}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        noOptionsText="找不到搜尋的人員"
                                        options={nextUserList}
                                        value={nextUser}
                                        onChange={(event, newValue) => {
                                            setNextUser(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            getUserInfo(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="請輸入人事號(可輸入姓名)" />}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props}>
                                                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        {option.account} {option.name} {option.group_name}
                                                    </Typography>
                                                </li>
                                            )

                                        }}
                                    />
                                </Box> : null
                        }

                        {
                            havePreviousProcess ?
                                <Box sx={{ backgroundColor: "#fbffa0", p: 2 }}>
                                    <Typography variant="h6" gutterBottom >{`※ 此表單被退回：${previousProcess.user_opinion}`}</Typography>
                                </Box> : null
                        }

                        {
                            questions.map((question) => (
                                questionsList(question)
                            ))
                        }

                        <Accordion sx={{ backgroundColor: '#FFE7C9' }}>
                            <AccordionSummary>
                                <Typography sx={{ color: '#97041B' }}>簽核意見</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FeedbackCard>
                                    <FeedbackCardHeader title="目前簽核狀態" />
                                    <FeedbackCardContent>
                                        <Stack direction="row" useFlexGap flexWrap="wrap">
                                            {
                                                feedback.map((item, index) => {
                                                    let color = 'success'
                                                    if (history[index].status === '進行中')
                                                        color = 'warning'
                                                    else if (history[index].status.length === 0)
                                                        color = 'secondary'
                                                    if (index < feedback.length - 1) {
                                                        return (
                                                            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" sx={{ mt: 1, mb: 1 }}>
                                                                <Chip
                                                                    label={`${item.index}. ${item.process_type_name}：${item.user_name}(${item.user_role_name}) `}
                                                                    color={color}
                                                                    size="small"
                                                                    sx={{ color: "#fff" }}
                                                                />
                                                                <ArrowForwardIcon />
                                                            </Stack>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div>
                                                                <Chip
                                                                    label={`${item.index}. ${item.process_type_name}：${item.user_name}(${item.user_role_name}) `}
                                                                    color={color}
                                                                    size="small"
                                                                    sx={{ color: "#fff", mt: 1, mb: 1 }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </Stack>
                                    </FeedbackCardContent>
                                </FeedbackCard>
                                <FeedbackCard>
                                    <FeedbackCardHeader title="簽核決定" />
                                    <FeedbackCardContent>
                                        <FormControl component="fieldset" >
                                            <RadioGroup
                                                aria-label="position"
                                            >
                                                {
                                                    sign.state.map((sign_state) => {
                                                        if (sign_state.sign_state === 7) {
                                                            return (
                                                                <>
                                                                    <FormControlLabel
                                                                        value={sign_state.sign_state}
                                                                        control={<Radio />}
                                                                        label={sign_state.name}
                                                                        labelPlacement="End"
                                                                        {...register(`sign_state`)}
                                                                    />
                                                                    <Select
                                                                        labelId={`sign_state_back_to_process_id`}
                                                                        id={`sign_state_back_to_process_id`}
                                                                        {...register(`sign_state_back_to_process_id`)}
                                                                    >
                                                                        {
                                                                            sign_state.back_process.map((item) => {
                                                                                if (!item.disabled)
                                                                                    return (
                                                                                        <MenuItem value={item.process_id}>{item.content}</MenuItem>
                                                                                    )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <FormControlLabel
                                                                    value={sign_state.sign_state}
                                                                    control={<Radio />}
                                                                    label={sign_state.name}
                                                                    labelPlacement="End"
                                                                    {...register(`sign_state`)}
                                                                />
                                                            )
                                                        }
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </FeedbackCardContent>
                                </FeedbackCard>
                                <FeedbackCard>
                                    <FeedbackCardHeader title="簽核意見" />
                                    <FeedbackCardContent>
                                        <TextField
                                            fullWidth
                                            multiline
                                            defaultValue={sign.opinion}
                                            rows={4}
                                            {...register(`sign_opinion`)}
                                        />
                                    </FeedbackCardContent>
                                </FeedbackCard>
                            </AccordionDetails>
                        </Accordion>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="warning"
                                    sx={{
                                        marginBottom: '1rem!important',
                                        '&:hover': {
                                            boxShadow: '0 0 0 0.25rem rgb(219 162 171 / 25%)'
                                        }
                                    }}
                                    onClick={handleSubmit((data) => tmpSaveOnClick(data))}
                                >
                                    暫存
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{
                                        marginBottom: '1rem!important',
                                        '&:hover': {
                                            boxShadow: '0 0 0 0.25rem rgb(219 162 171 / 25%)'
                                        }
                                    }}
                                    onClick={handleSubmit((data) => submitOnClick(data))}
                                >
                                    送出
                                </Button>
                            </Grid>
                        </Grid>
                    </>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} sx={{ whiteSpace: 'pre-line' }}>
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessages}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default PendingFormPage;