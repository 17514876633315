// @ts-nocheck
import { styled } from '@mui/material/styles';

export const H1 = styled('h1')({
    color: window.style.primary.main,
    fontWeight: 500,
    fontSize: '1.6em',
    fontFamily: 'Helvetica',
    marginTop: '2rem',
    textAlign: 'center'
});