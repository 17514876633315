import { styled } from "@mui/system";
import { Button as MuiButton } from "@mui/material";

export const Button = styled(MuiButton)(({ theme }) =>
    theme.unstable_sx({
    })
);

export const SquareButton = styled(MuiButton)(({ theme }) =>
    theme.unstable_sx({
        width: '90px',
        height: '90px',
        borderRadius: '10px',
    })
);