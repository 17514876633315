import { useEffect, useState } from "react"
import SpeedDial from "../../Component/SpeedDial";
import { CardActions, CardContent, CourseCard, CourseCardHeader } from "../../Component/Card";
import { Grid, Typography, CircularProgress, Toolbar, Container, Box, IconButton, Chip } from "@mui/material";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { CachedIcon } from "../../Icon/CachedIcon";
import axios from 'axios';
import useConfig from "../../Hooks/useConfig";

const TodayCoursePage = () => {
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState(false);
    const api = useConfig('api');
    const actions = [
        {
            icon: <CachedIcon />,
            name: '重新整理',
            click: () => getData()
        },
    ];
    const getData = async () => {
        const url = api + "admin/common/course/course_list/get_today_course";
        setCourseList([]);
        setLoading(true);
        const response = await axios({
            method: 'post',
            url: url,
            withCredentials: true
        })
        setCourseList(response.data.data.today_course);
        setLoading(false);
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            <Toolbar sx={{ padding: '0.5rem 0px' }} />
            <Container>
                {
                    loading ?
                        <Box sx={{ textAlign: "center", paddingTop: '1rem' }}>
                            <CircularProgress />
                        </Box>
                        : null
                }
                {
                    courseList.length === 0 ?
                        <Box sx={{ marginTop: '40vh', textAlign: 'center' }}>
                            <Typography variant="body2" gutterBottom >今日無課程</Typography>
                        </Box>
                        : null
                }
                <Grid container spacing={2}>
                    {
                        courseList.map((course) => (
                            <Grid xs={12} sx={{ mt: 1 }}>
                                <CourseCard>
                                    <CourseCardHeader
                                        title={course.course_name}
                                    />
                                    <CardContent sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                        <Typography>
                                            <AvTimerIcon />
                                            開始時間
                                        </Typography>
                                        <Typography>
                                            {
                                                (new Date(course.start_time * 1000)).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })
                                            }
                                        </Typography>
                                        <Typography>
                                            <AvTimerIcon />
                                            結束時間
                                        </Typography>
                                        <Typography>
                                            {
                                                (new Date(course.end_time * 1000)).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })
                                            }
                                        </Typography>
                                        <Typography>
                                            <LocationOnIcon />
                                            上課地點
                                        </Typography>
                                        <Typography>
                                            {course.place}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Chip label={course.course_mode} color="primary" />
                                            </Grid>
                                            <Grid item xs={4} sx={{ textAlign: 'end' }}>
                                                <IconButton sx={{ color: '#FFF', backgroundColor: '#1976D2' }} onClick={() => { window.location.href = '/todycourse/' + course.course_id }}>
                                                    <ArrowRightAltIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </CourseCard>
                            </Grid>

                        ))
                    }
                </Grid>

            </Container>
            <SpeedDial actions={actions} />
        </>
    )
}

export default TodayCoursePage;