//@ts-nocheck
import { Card as MuiCard, CardContent as MuiCardContent, CardHeader as MuiCardHeader, CardActions as MuiCardActions } from '@mui/material/';
import { styled } from '@mui/system';

export const Card = styled(MuiCard)(({ theme }) =>
    theme.unstable_sx({
        background: "#FFFFFF",
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        mt: 1,
        mb: 1
    })
);

export const CardHeader = styled(MuiCardHeader)(({ theme }) =>
    theme.unstable_sx({
        color: window.style.primary.main
    })
);

export const CardContent = styled(MuiCardContent)(({ theme }) =>
    theme.unstable_sx({
        padding: '5px 16px'
    })
);

export const CardActions = styled(MuiCardActions)(({ theme }) =>
    theme.unstable_sx({

    })
);

export const QuestionCard = styled(MuiCard)(({ theme }) =>
    theme.unstable_sx({
        background: "#D4E5F8",
        borderRadius: '8px',
        mt: 1,
        mb: 1
    })
);

export const QuestionCardHeader = styled(MuiCardHeader)(({ theme }) =>
    theme.unstable_sx({
        padding: "16px 16px 0",
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px'
    })
);

export const QuestionCardContent = styled(MuiCardContent)(({ theme }) =>
    theme.unstable_sx({
        margin: '10px',
        padding: '20px',
        background: "#FFFFFF",
        borderRadius: '8px',
        paddingTop: '30px'
    })
);

export const FeedbackCard = styled(MuiCard)(({ theme }) =>
    theme.unstable_sx({
        background: "#F9F9F9",
        borderRadius: '4px',
        mt: 1,
        mb: 1
    })
);

export const FeedbackCardHeader = styled(MuiCardHeader)(({ theme }) =>
    theme.unstable_sx({
        padding: "16px 16px 0",
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px'
    })
);

export const FeedbackCardContent = styled(MuiCardContent)(({ theme }) =>
    theme.unstable_sx({
        margin: '10px',
        padding: '10px',
        background: "#FFFFFF",
        borderRadius: '8px',
        paddingTop: '20px'
    })
);

export const CourseCard = styled(MuiCard)(({ theme }) =>
    theme.unstable_sx({
        background: "#F9F9F9",
        borderRadius: '10px',
        m: 2,
        minWidth: '162px'
    })
);

export const CourseCardHeader = styled(MuiCardHeader)(({ theme }) =>
    theme.unstable_sx({
        padding: "16px 16px 0",
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px'
    })
);
