import { QuestionCard, QuestionCardContent, QuestionCardHeader } from "../../Component/Card";
import { Typography, RadioGroup, FormGroup, FormControlLabel, FormControl, Radio, MenuItem, InputLabel, TextField, Checkbox, Box, List, ListItem, ListItemAvatar, ListItemText, IconButton, Avatar } from "@mui/material";
import Select from '@mui/material/Select';
import { Controller } from "react-hook-form";
import { MuiFileInput } from "mui-file-input";
import useConfig from "../../Hooks/useConfig";
import { useEffect, useState, useReducer } from "react";
import axios from "axios";
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { isArray } from "util";

const Questions = (props) => {
    const { ai_id, questions, control, register, errors } = props;
    const [fileList, setFileList] = useState([]);
    const [abilityScoreList, setAbilityScoreList] = useState({});
    const [satisfactionList, setsatisfactionList] = useState({});
    const api = useConfig('api');
    const getFileList = async (id) => {
        let url = `${api}admin/common/form/get_question_file_list`
        const formData = new FormData();
        formData.append("is_pwa", 1);
        formData.append("ai_id", ai_id);
        formData.append("ques_id", id);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        });
        setFileList(response.data.data.file_list);
    }
    useEffect(() => {
        questions.map((question) => {
            let list = {}
            if (question.type === 9 || question.type === 10) {
                let ans = question.answer?.no_tags_ans_text ? question.answer?.no_tags_ans_text : 0
                let max = parseInt(question.custom_attr?.max ? question.custom_attr.max : question.max)
                if (ans === 'NA') {
                    ans = 0
                    max = 0
                }
                else {
                    ans = parseInt(ans)
                }
                list[`${question.ques_id}`] = {
                    max: max,
                    value: ans
                }
                if (question.type === 9)
                    setAbilityScoreList(abilityScoreList => ({
                        ...abilityScoreList,
                        ...list
                    }))
                else if (question.type === 10)
                    setsatisfactionList(satisfactionList => ({
                        ...satisfactionList,
                        ...list
                    }))
            }
            if (question.type === 11)
                getFileList(question.ques_id);
        })
    }, [])

    useEffect(() => {
        let allMax = 0;
        let allValue = 0;
        let totalScore = 0;
        let objectKeys = Object.keys(abilityScoreList);
        for (let i = 0; i < objectKeys.length; i++) {
            allMax += abilityScoreList[objectKeys[i]].max
            allValue += abilityScoreList[objectKeys[i]].value
        }
        totalScore = allValue / allMax * 100;
        if (document.getElementById('abilityScoreValue')) {
            document.getElementById('abilityScoreValue').value = totalScore
        }
    }, [abilityScoreList])


    useEffect(() => {
        let allMax = 0;
        let allValue = 0;
        let totalScore = 0;
        let objectKeys = Object.keys(satisfactionList);
        for (let i = 0; i < objectKeys.length; i++) {
            allMax += satisfactionList[objectKeys[i]].max
            allValue += satisfactionList[objectKeys[i]].value
        }
        totalScore = allValue / allMax * 100;
        if (document.getElementById('satisfactionValue')) {
            document.getElementById('satisfactionValue').value = totalScore
        }
    }, [satisfactionList])

    const questionContent = (question) => {
        let ans = question.answer?.no_tags_ans_text ? question.answer.no_tags_ans_text : "";
        let required = question.required === 1 ? true : false
        let path = question.ques_path ? question.ques_path.split('-') : [];
        let isDisabled = question.disabled ? question.disabled : false;
        let max = null;
        let marks = [];
        let list = {};

        switch (question.type) {
            case 1:
                return (
                    <Typography variant="h5" gutterBottom>
                        {question.content}
                    </Typography>
                )
            case 2:
                return (
                    <Typography variant="body1" gutterBottom>
                        {question.content}
                    </Typography>
                )
            case 3:
                return (
                    <Typography variant="body1" gutterBottom>
                        {question.content}
                    </Typography>
                )
            case 6:
                let answer = -1;
                let answerText = {};
                if (ans) {
                    answer = ans[0]
                    if (ans['text']) {
                        answerText = ans['text']
                    }
                }
                return (
                    <>
                        <FormControl component="fieldset" disabled={isDisabled}>
                            <RadioGroup
                                id={question.ques_id}
                                aria-label="position"
                                defaultValue={answer}
                            >
                                {
                                    question.options.map((ques, index) => {
                                        if (question.has_text[index]) {
                                            return (
                                                <>
                                                    <FormControlLabel
                                                        value={index + 1}
                                                        control={<Radio />}
                                                        label={ques}
                                                        labelPlacement="End"
                                                        {...register(`form_question[${path[0]}][${path[1]}][ans]`, { required: required })}
                                                    />
                                                    <TextField
                                                        variant="standard"
                                                        label="請輸入文字"
                                                        defaultValue={answerText[`${index + 1}`]}
                                                        {...register(`form_question[${path[0]}][${path[1]}][text][${index + 1}]`, { required: required })}
                                                    />
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <FormControlLabel
                                                    value={index + 1}
                                                    control={<Radio />}
                                                    label={ques}
                                                    labelPlacement="End"
                                                    {...register(`form_question[${path[0]}][${path[1]}][ans]`, { required: required })}
                                                />
                                            )
                                        }
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </>
                )
            case 7:
                let ansArrary = [];
                if (ans) {
                    if (ans['text']) {
                        const size = Object.keys(ans).length;
                        for (let i = 0; i < size - 1; i++) {
                            ansArrary.push(ans[i]);
                        }
                    }
                    else {
                        ansArrary = ans
                    }
                }
                return (
                    <FormGroup id={question.ques_id}>
                        {
                            question.options.map((ques, index) => {
                                let checked = false;
                                for (let i = 0; i < ansArrary.length; i++) {
                                    if (ansArrary[i] == index + 1)
                                        checked = true;
                                }
                                return (
                                    <FormControlLabel
                                        value={index + 1}
                                        control={<Checkbox defaultChecked={checked} />}
                                        label={ques}
                                        labelPlacement="End"
                                        disabled={isDisabled}
                                        {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                                    />
                                )
                            })
                        }
                    </FormGroup>
                )
            case 8:
                if (question.class === '1') {
                    return (
                        <>
                            <TextField
                                fullWidth
                                id={question.ques_id}
                                label={question.title}
                                multiline
                                defaultValue={ans}
                                rows={4}
                                disabled={isDisabled}
                                {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            />
                        </>
                    )
                }
                else if (question.class === '2') {
                    return (
                        <>
                            <TextField
                                fullWidth
                                id={question.ques_id}
                                label={question.title}
                                defaultValue={ans}
                                disabled={isDisabled}
                                {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            />
                        </>
                    )
                }
                else
                    break;
            case 9:
                max = parseInt(question.custom_attr?.max ? question.custom_attr.max : question.max);
                if (question.custom_attr?.score_text) {
                    const size = Object.keys(question.custom_attr.score_text).length;
                    const buffer = max / size
                    for (let i = 1; i <= max; i++) {
                        for (let j = 0; j < size; j++) {
                            if (i > j * buffer) {
                                marks[i] = {
                                    value: i,
                                    label: `${i} - ` + question.custom_attr.score_text[`${j}`]
                                }
                            }
                        }
                    }
                    if (question.custom_attr.show_NA_option === 1) {
                        marks.push({
                            value: 'NA',
                            label: 'NA'
                        })
                    }
                }
                else {
                    for (let i = 1; i <= max; i++) {
                        marks[i] = {
                            value: i,
                            label: `${i}`
                        }
                    }
                }
                return (
                    <FormControl fullWidth>
                        <InputLabel id={`form_question[${path[0]}][${path[1]}]-label`}>{question.title}</InputLabel>
                        <Select
                            labelId={`form_question[${path[0]}][${path[1]}]`}
                            id={`form_question[${path[0]}][${path[1]}]`}
                            defaultValue={ans}
                            disabled={isDisabled}
                            {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            onChange={(e) => {
                                if (e.target.value === 'NA') {
                                    list[`${question.ques_id}`] = {
                                        max: 0,
                                        value: 0
                                    }
                                    setAbilityScoreList(abilityScoreList => ({
                                        ...abilityScoreList,
                                        ...list
                                    }))
                                }
                                else {
                                    list[`${question.ques_id}`] = {
                                        max: max,
                                        value: parseInt(e.target.value)
                                    }
                                    setAbilityScoreList(abilityScoreList => ({
                                        ...abilityScoreList,
                                        ...list
                                    }))
                                }
                            }}
                        >
                            {
                                marks.map((item) => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )

            case 10:
                max = parseInt(question.custom_attr?.max ? question.custom_attr.max : question.max);
                if (question.custom_attr?.score_text) {
                    const size = Object.keys(question.custom_attr.score_text).length;
                    const buffer = max / size
                    for (let i = 1; i <= max; i++) {
                        for (let j = 0; j < size; j++) {
                            if (i > j * buffer) {
                                marks[i] = {
                                    value: i,
                                    label: `${i} - ` + question.custom_attr.score_text[`${j}`]
                                }
                            }
                        }
                    }
                    if (question.custom_attr.show_NA_option === 1) {
                        marks.push({
                            value: 'NA',
                            label: 'NA'
                        })
                    }
                }
                else {
                    for (let i = 1; i <= max; i++) {
                        marks[i] = {
                            value: i,
                            label: `${i}`
                        }
                    }
                }
                return (
                    <FormControl fullWidth>
                        <InputLabel id={`form_question[${path[0]}][${path[1]}]-label`}>{question.title}</InputLabel>
                        <Select
                            labelId={`form_question[${path[0]}][${path[1]}]`}
                            id={`form_question[${path[0]}][${path[1]}]`}
                            defaultValue={ans}
                            disabled={isDisabled}
                            {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            onChange={(e) => {
                                if (e.target.value === 'NA') {
                                    list[`${question.ques_id}`] = {
                                        max: 0,
                                        value: 0
                                    }
                                    setsatisfactionList(satisfactionList => ({
                                        ...satisfactionList,
                                        ...list
                                    }))
                                }
                                else {
                                    list[`${question.ques_id}`] = {
                                        max: max,
                                        value: parseInt(e.target.value)
                                    }
                                    setsatisfactionList(satisfactionList => ({
                                        ...satisfactionList,
                                        ...list
                                    }))
                                }
                            }}
                        >
                            {
                                marks.map((item) => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                )
            case 11:

                const handleChange = async (newFile) => {
                    let url = `${api}admin/common/form/upload_question_file`
                    const formData = new FormData();
                    formData.append("is_pwa", 1);
                    formData.append("ai_id", ai_id);
                    formData.append("ques_id", question.ques_id);
                    formData.append("file", newFile);
                    const response = await axios({
                        method: 'post',
                        url: url,
                        data: formData,
                        withCredentials: true
                    });
                    if (response.data.status) {
                        getFileList(question.ques_id);
                    }
                }
                const handleDeleteClick = async (id) => {
                    let url = `${api}admin/common/form/delete_assessment_file`
                    const formData = new FormData();
                    formData.append("is_pwa", 1);
                    formData.append("file_id", id);
                    const response = await axios({
                        method: 'post',
                        url: url,
                        data: formData,
                        withCredentials: true
                    });
                    if (response.data.status) {
                        getFileList(question.ques_id);
                    }
                }

                return (
                    <>
                        <List>
                            {
                                fileList.map((file) => (
                                    <ListItem
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => { handleDeleteClick(file.file_id) }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <a href={file.download_url}>{file.file_name}</a>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <MuiFileInput
                            placeholder="上傳檔案"
                            onChange={handleChange}
                        />
                    </>
                )
            case 12:
                return (
                    <Box sx={{ textAlign: 'center' }}>
                        <p>手機板不支援此題組，請至電腦版使用。</p>
                    </Box>
                )
            case 13:
                return (
                    <>
                        <TextField
                            fullWidth
                            InputLabelProps={{ required: required }}
                            type="date"
                            defaultValue={ans.date}
                            {...register(`form_question[${path[0]}][${path[1]}][date]`, { required: required })}
                        />
                    </>
                )
            case 14:
                return (
                    <>
                        <TextField
                            fullWidth
                            id={question.ques_id}
                            label={question.title}
                            multiline
                            defaultValue={ans}
                            rows={4}
                            disabled={isDisabled}
                            {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                        />
                    </>
                )
            case 15:
                return (
                    <Box sx={{ textAlign: 'center' }}>
                        {question.answer.role_name}簽名：
                        <span
                            style={{
                                color: "rgb(203, 0, 0)",
                                display: "inline-block",
                                border: '2px solid',
                                padding: '1px 2px 0',
                                lineHeight: '16px',
                                borderRadius: '4px',
                                fontFamily: "cwTexHei",
                            }}
                        >
                            {question.answer.user_name}
                        </span>
                    </Box>
                )
            case 16:
                return (
                    <img src={`${api}admin/common/form/show_question_image/${question.ques_id}`} style={{ width: '100%' }} />
                )
            case 17:
                if (question.class === '13')
                    return (
                        <Box>
                            <TextField
                                id='abilityScoreValue'
                                variant="standard"
                                type="number"
                                disabled
                                fullWidth
                                {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            />
                        </Box>
                    )
                else if (question.class === '11')
                    return (
                        <Box>
                            <TextField
                                id='satisfactionValue'
                                variant="standard"
                                type="number"
                                disabled
                                fullWidth
                                {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                            />
                        </Box>
                    )
            case 18:
                return (
                    <>
                        <FormControl component="fieldset">
                            <RadioGroup
                                id={question.ques_id}
                                aria-label="position"
                                defaultValue={ans[0]}
                            >
                                {
                                    question.options.map((ques, index) => (
                                        <FormControlLabel
                                            value={index + 1}
                                            control={<Radio />}
                                            label={ques}
                                            labelPlacement="End"
                                            disabled={isDisabled}
                                            {...register(`form_question[${path[0]}][${path[1]}][ans]`, { required: required })}
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </>
                )
            case 19:
                return (
                    <>
                        <TextField
                            fullWidth
                            id={question.ques_id}
                            label={question.title}
                            variant="standard"
                            disabled={isDisabled}
                            defaultValue={ans}
                            {
                            ...register(`form_question[${path[0]}][${path[1]}]`, { required: required, value: ans, })
                            }
                        />
                    </>
                )
            case 20:
                return (
                    <>
                        <TextField
                            fullWidth
                            id={question.ques_id}
                            label={question.title}
                            defaultValue={ans}
                            disabled={isDisabled}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 100 }}
                            type="number"
                            {...register(`form_question[${path[0]}][${path[1]}]`, { required: required })}
                        />
                    </>
                )
            case 21:
                return (
                    <Box sx={{ textAlign: 'center' }}>
                        <p>手機板不支援此題組，請至電腦版使用。</p>
                    </Box>
                )
            default:
                break;
        }
    }
    return (
        <>
            {
                questions.map((question) => (
                    <QuestionCard
                    >
                        <QuestionCardHeader
                            title={question.title}
                        />
                        <QuestionCardContent>
                            {questionContent(question)}
                        </QuestionCardContent>
                    </QuestionCard>
                ))
            }
        </>
    )
}

export default Questions;