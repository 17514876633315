import { ComponentStory, ComponentMeta } from "@storybook/react";

import { Button, SquareButton } from ".";

export default {
  title: "Component/Button",
  component: Button,
} as ComponentMeta<typeof Button>;

export const LoginButton: ComponentStory<typeof Button> = (args) => (
  <Button
    {...args}
    variant="contained"
    size="large"
    fullWidth={true}
    color="primary"
    sx={{
      marginBottom: '1rem!important',
      '&:hover': {
        boxShadow: '0 0 0 0.25rem rgb(219 162 171 / 25%)'
      }
    }}
  >
    登入
  </Button>
);

export const ForgetPasswordButton: ComponentStory<typeof Button> = (args) => (
  <Button
    {...args}
    variant="contained"
    size="large"
    fullWidth={true}
    color="inherit"
    sx={{
      marginBottom: '1rem!important',
      '&:hover': {
        boxShadow: '0 0 0 0.25rem rgb(192 192 209 / 25%)'
      }
    }}
  >
    忘記密碼
  </Button>
);

export const SubmitButton: ComponentStory<typeof Button> = (args) => (
  <Button
    {...args}
    variant="contained"
    size="large"
    fullWidth={true}
    color="primary"
    sx={{
      marginBottom: '1rem!important',
      '&:hover': {
        boxShadow: '0 0 0 0.25rem rgb(219 162 171 / 25%)'
      }
    }}
  >
    送出
  </Button>
);

export const GoBackLoginButton: ComponentStory<typeof Button> = (args) => (
  <Button
    {...args}
    variant="contained"
    size="large"
    fullWidth={true}
    color="inherit"
    sx={{
      marginBottom: '1rem!important',
      '&:hover': {
        boxShadow: '0 0 0 0.25rem rgb(192 192 209 / 25%)'
      }
    }}
  >
    回登入
  </Button>
);

export const HomeSquareButton: ComponentStory<typeof SquareButton> = (args) => (
  <SquareButton
    {...args}
    variant="contained"
    size="large"
  >
    表單待辦
  </SquareButton>
);

export const HomeSquareButtonDisabled: ComponentStory<typeof SquareButton> = (args) => (
  <SquareButton
    {...args}
    variant="contained"
    size="large"
    disabled={true}
  >
    課程待辦
  </SquareButton>
);