import { Box, Container, Grid, Toolbar } from "@mui/material";
import useConfig from "../../Hooks/useConfig";
import { Image } from "../../Component/Image";
import { SquareButton } from "../../Component/Button";

const buttonList = [
    {
        name: '表單待辦',
        path: "/pending",
        disabled: false
    },
    {
        name: '行事曆',
        path: "/calendar",
        disabled: false
    },
    {
        name: '今日課程',
        path: "/todycourse",
        disabled: false
    },
];

const HomePage: React.FC = () => {
    const images = useConfig('image');
    return (
        <Container maxWidth='sm'>
            <Toolbar sx={{ padding: '0.5rem 0px' }} />
            <Image src={images.background} sx={{ width: "100%", m: 0 }} />
            <Box>
                <Grid container spacing={4} >
                    {
                        buttonList.map((button) => (
                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                                <SquareButton
                                    variant="contained"
                                    size="large"
                                    disabled={button.disabled}
                                    onClick={() => window.location.href = button.path}
                                >
                                    {button.name}
                                </SquareButton>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Container>
    )
}

export default HomePage;