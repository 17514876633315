//@ts-nocheck
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler, DayView, Appointments, MonthView, Toolbar,
  DateNavigator, ViewSwitcher, TodayButton, Resources,
  AppointmentTooltip, WeekView, AllDayPanel, CurrentTimeIndicator
} from '@devexpress/dx-react-scheduler-material-ui';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Paper, Toolbar as MuiToolbar } from '@mui/material/';
import Room from '@mui/icons-material/Room';
import classNames from 'clsx';
import useConfig from '../../Hooks/useConfig';
import axios from 'axios';

const PREFIX = 'Calendar';

const classes = {
  appointment: `${PREFIX}-appointment`,
  highPriorityAppointment: `${PREFIX}-highPriorityAppointment`,
  mediumPriorityAppointment: `${PREFIX}-mediumPriorityAppointment`,
  lowPriorityAppointment: `${PREFIX}-lowPriorityAppointment`,
  weekEndCell: `${PREFIX}-weekEndCell`,
  weekEndDayScaleCell: `${PREFIX}-weekEndDayScaleCell`,
  text: `${PREFIX}-text`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`
};

const resources = [
  {
    fieldName: 'event',
    instances: [
      { id: 'course_cross_college_events', text: '全院課程', color: '#DAA520' },
      { id: 'common_group_course_events', text: '全科課程', color: '#DAA520' },
      { id: 'personal_events', text: '我發起的事件', color: '#3A87AD' },
      { id: 'participant_events', text: '受邀請事件', color: '#47A3D1' },
      { id: 'course_events', text: '一般課程', color: '#009688' },
      { id: 'course_share_events', text: '跨計畫課程', color: '#40C9BB' },
      { id: 'course_as_auto_assist_events', text: '預設協同', color: '#DAA520' },
      { id: 'course_as_tea_events', text: '擔任課程教師', color: '#ED0250' },
      { id: 'course_as_assist_events', text: '擔任協同教師', color: '#EA3C8E' },
      { id: 'course_other_events', text: '科部內其它課程', color: '#56A391' },
      { id: 'course_as_stu_events', text: '需上課程', color: '#05ad53' },
      { id: 'batch_events', text: '計畫期間', color: '#9C27B0' },
      { id: 'course_signup_events', text: '可報名課程', color: '#DAA520' },
    ],
  },
]

const groupingStyles = ({ theme }) => ({
  [`&.${classes.cellLowPriority}`]: stylesByPriority.cellLowPriority,
  [`&.${classes.cellMediumPriority}`]: stylesByPriority.cellMediumPriority,
  [`&.${classes.cellHighPriority}`]: stylesByPriority.cellHighPriority,
  [`&.${classes.headerCellLowPriority}`]: stylesByPriority.headerCellLowPriority,
  [`&.${classes.headerCellMediumPriority}`]: stylesByPriority.headerCellMediumPriority,
  [`&.${classes.headerCellHighPriority}`]: stylesByPriority.headerCellHighPriority,
  [`& .${classes.icon}`]: {
    paddingLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
});

const StyledMonthViewDayScaleCell = styled(MonthView.DayScaleCell)(({ theme: { palette } }) => ({
  [`&.${classes.weekEndDayScaleCell}`]: {
    backgroundColor: alpha(palette.action.disabledBackground, 0.06),
  },
}));

const StyledMonthViewTimeTableCell = styled(MonthView.TimeTableCell)(({ theme: { palette } }) => ({
  [`&.${classes.weekEndCell}`]: {
    backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    '&:hover': {
      backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    },
    '&:focus': {
      backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    },
  },
}));

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.textCenter}`]: {
    textAlign: 'center',
  },
}));

const StyledRoom = styled(Room)(({ theme: { palette } }) => ({
  [`&.${classes.icon}`]: {
    color: palette.action.active,
  },
}));

const isWeekEnd = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6;
const defaultCurrentDate = new Date();

const DayScaleCell = (({
  startDate, ...restProps
}: MonthView.DayScaleCellProps) => (
  <StyledMonthViewDayScaleCell
    className={classNames({
      [classes.weekEndDayScaleCell]: isWeekEnd(startDate),
    })}
    startDate={startDate}
    {...restProps}
  />
));

const TimeTableCell = ((
  { startDate, ...restProps }: MonthView.TimeTableCellProps,
) => (
  <StyledMonthViewTimeTableCell
    className={classNames({
      [classes.weekEndCell]: isWeekEnd(startDate!),
    })}
    startDate={startDate}
    {...restProps}
  />
));

const Content = (({
  children, appointmentData, ...restProps
}) => (
  <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
    <Grid container alignItems="center" >
      <StyledGrid item xs={2} className={classes.textCenter} sx={{ textAlign: 'center' }}>
        <StyledRoom className={classes.icon} />
      </StyledGrid>
      <Grid item xs={10}>
        <span>{appointmentData.location}</span>
      </Grid>
    </Grid>
  </AppointmentTooltip.Content>
));

const CalendarPage = () => {
  const [calendarList, setCalendarList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const api = useConfig('api');

  const getCalendarList = async (date) => {
    let now = new Date(date);
    let startyear = now.getFullYear();
    let startmonth = now.getMonth();
    let startday = '20';

    let endyear = now.getFullYear();
    let endmonth = now.getMonth() + 2;
    let endday = '10';

    let start = `${startyear}-${startmonth}-${startday}`;
    let end = `${endyear}-${endmonth}-${endday}`;

    const formData = new FormData();
    formData.append("start", start);
    formData.append("end", end);
    const url = api + "admin/common/calendar/get_calendar_list";
    setOpen(true);
    const response = await axios({
      method: 'post',
      url: url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true
    })
    const calendar_list = response.data.data.calendar_list;
    let list = [];
    for (let i = 0; i < calendar_list.length; i++) {
      list.push({
        title: calendar_list[i].title,
        startDate: new Date(calendar_list[i].start * 1000),
        endDate: new Date(calendar_list[i].end * 1000),
        event: calendar_list[i].event,
        location: calendar_list[i].place,
      })
    }
    setCalendarList(list);
    setOpen(false);
  }

  React.useEffect(() => {
    getCalendarList(new Date());
  }, [])

  return (
    <Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MuiToolbar sx={{ padding: '0.5rem 0px' }} />
      <Scheduler
        locale="zh-TW"
        data={calendarList}
      >
        <ViewState
          defaultCurrentDate={defaultCurrentDate}
          onCurrentDateChange={(currentDate) => getCalendarList(currentDate)}
        />

        <MonthView
          dayScaleCellComponent={DayScaleCell}
          timeTableCellComponent={TimeTableCell}
        />

        <WeekView
          startDayHour={6}
          endDayHour={24}
        />

        <DayView
          startDayHour={6}
          endDayHour={24}
        />

        <AllDayPanel
        />

        <Appointments />

        <Resources
          data={resources}
        />

        <AppointmentTooltip
          showCloseButton
          contentComponent={Content}
        />
        <CurrentTimeIndicator
          shadePreviousCells={true}
          shadePreviousAppointments={true}
          updateInterval={10000}
        />
        <Toolbar />
        <DateNavigator />
        <ViewSwitcher />
        <TodayButton />
      </Scheduler>
    </Paper>
  )
};

export default CalendarPage;