import { FormBody } from "../FormBody";
import { useState } from "react";
import { EmailInput, UserInput } from "../FormInput/FormInput.stories";
import { GoBackLoginButton, SubmitButton } from "../../Component/Button/Button.stories";

export const ForgetPasswordForm: React.FC = () => {
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    return (
        <FormBody>
            <UserInput
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
            />
            <EmailInput
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
            <SubmitButton />
            <GoBackLoginButton onClick={() => window.location.href = '/login'} />
        </FormBody>
    )
}