import { Grid, Typography } from "@mui/material";
import { Card, CardContent, CardHeader } from "../../Component/Card";

interface InfoCardProps {
    formID: string,
    formName: string,
    info: {
        batch_name: string,
        batch_year: string,
        group_name: string,
        form_type: string,
        assessment_user_name: string
    } | undefined
}

const InfoCard = (props: InfoCardProps) => {
    const { formID, formName, info } = props
    return (
        <>
            <Card>
                <CardHeader
                    title="表單代碼"
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <CardContent>
                    <Typography variant="body1">
                        {formID}
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title="表單名稱"
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <CardContent>
                    <Typography variant="body1">
                        {formName}
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title="訓練計畫"
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <CardContent>
                    <Typography variant="body1">
                        {info?.batch_name}
                    </Typography>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title="訓練科室"
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <CardContent>
                    <Typography variant="body1">
                        {info?.group_name}
                    </Typography>
                </CardContent>
            </Card>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title="學年度"
                            titleTypographyProps={{ variant: 'body1' }}
                        />
                        <CardContent>
                            <Typography variant="body1">
                                {info?.batch_year}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title="表單類型"
                            titleTypographyProps={{ variant: 'body1' }}
                        />
                        <CardContent>
                            <Typography variant="body1">
                                {info?.form_type}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title="受評者"
                            titleTypographyProps={{ variant: 'body1' }}
                        />
                        <CardContent>
                            <Typography variant="body1">
                                {info?.assessment_user_name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default InfoCard;