// @ts-nocheck
import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const FormInput = styled(TextField)(({ theme }) =>
    theme.unstable_sx({
        '& .MuiOutlinedInput-root': {
            color: window.style.primary.dark,
            backgroundColor: 'white',
            '& fieldset': {
                borderColor: window.style.primary.dark,
            },
            '&:hover fieldset': {
                borderColor: window.style.primary.dark,
            },
            '&.Mui-focused fieldset': {
                color: window.style.primary.light,
                borderColor: window.style.primary.light,
                outline: 0,
                boxShadow: '0 0 0 0.25rem rgb(146 55 68 / 25%)'
            },
        },
        '& .MuiSvgIcon-root': {
            color: window.style.primary.dark
        }
    })
);