import { styled } from '@mui/material/styles';
import { Box, AppBar as MuiAppBar, Toolbar, Typography } from '@mui/material';
import { IconButton } from '../IconButton';
import { Badge } from '../Badge';
import { NotificationsIcon } from '../../Icon/NotificationsIcon';
import { MoreIcon } from '../../Icon/MoreIcon';
import { CloseIcon } from '../../Icon/CloseIcon';
import { KeyboardBackspaceIcon } from '../../Icon/KeyboardBackspaceIcon';
import ChatIcon from '@mui/icons-material/Chat';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useEffect, useState } from 'react';

export const AppBar = styled(MuiAppBar)(({ theme }) =>
    theme.unstable_sx({
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important',
        backgroundColor: 'rgba(255,255,255,0.8)',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '.5rem 0'
    })
);

interface LayoutPageAppBar {
    setDialogOpen?(): any,
    setChatDialogOpen?(): any,
    setNotifyDialogOpen?():any,
    unreadMessageCount: number,
    unreadNotifyCount: number
}

interface SettingDialogAppBarProps {
    handleDialogClose?(): any
}

interface ChatListDialogAppBarProps {
    handleDialogClose?(): any,
    handleAddGroupDialogOpen?(): any,

}

interface ChatDialogAppBarProps {
    handleDialogClose?(): any,
    handleAddUserDialogOpen?(): any
    groupName: string
}

export const LayoutPageAppBar = (props: LayoutPageAppBar) => {
    const { setDialogOpen, setChatDialogOpen,setNotifyDialogOpen, unreadMessageCount, unreadNotifyCount } = props;
    return (
        <AppBar>
            <Toolbar>
                <Box sx={{ display: 'flex' }}>
                    {
                        window.location.pathname !== "/" ?
                            <IconButton
                                edge="end"
                                size="large"
                                onClick={() => {
                                    let href = window.location.pathname.split('/');
                                    let history = "";
                                    if (href[href.length - 1].length === 0)
                                        href.pop();
                                    href.pop();
                                    for (let i = 0; i < href.length; i++) {
                                        history += `${href[i]}/`
                                    }
                                    window.location.href = history;
                                }}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            : null
                    }
                    <Typography sx={{ color: '#000' }}>臨床教育 E-Portfolio 2.0版 APP</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex' }}>
                    {
                        unreadNotifyCount === 0 ?
                            <IconButton
                                edge="end"
                                size="large"
                                onClick={setNotifyDialogOpen}
                            >
                                <NotificationsIcon />
                            </IconButton>
                            :
                            <Badge badgeContent={unreadNotifyCount} color="primary">
                                <IconButton
                                    edge="end"
                                    size="large"
                                    onClick={setNotifyDialogOpen}
                                >
                                    <NotificationsIcon />
                                </IconButton>
                            </Badge>
                    }
                    {
                        unreadMessageCount === 0 ?
                            <IconButton
                                edge="end"
                                size="large"
                                onClick={setChatDialogOpen}
                            >
                                <ChatIcon />
                            </IconButton>
                            :
                            <Badge badgeContent={unreadMessageCount} color="primary">
                                <IconButton
                                    edge="end"
                                    size="large"
                                    onClick={setChatDialogOpen}
                                >
                                    <ChatIcon />
                                </IconButton>
                            </Badge>
                    }

                    <IconButton
                        edge="end"
                        size="large"
                        onClick={setDialogOpen}
                    >
                        <MoreIcon />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
};

export const SettingDialogAppBar = (props: SettingDialogAppBarProps) => {
    const { handleDialogClose, ...other } = props;

    return (
        <AppBar
            position="static"
        >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export const ChatListDialogAppBar = (props: ChatListDialogAppBarProps) => {
    const { handleDialogClose, handleAddGroupDialogOpen, ...other } = props;

    return (
        <AppBar
            position="static"
        >
            <Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        edge="end"
                        size="large"
                        onClick={handleAddGroupDialogOpen}
                    >
                        <GroupAddIcon />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export const ChatDialogAppBar = (props: ChatDialogAppBarProps) => {
    const { handleAddUserDialogOpen, handleDialogClose, groupName, ...other } = props;

    return (
        <AppBar
            position="static"
        >
            <Toolbar>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography sx={{ color: 'black' }}>{groupName}</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        edge="end"
                        size="large"
                        onClick={handleAddUserDialogOpen}
                    >
                        <PersonAddAlt1Icon />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}