import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import LoginBody from '../../Component/LoginBody';
import { LoginBox } from '../../Component/LoginBox';
import { NavBar } from '../../Component/NavBar';
import { A } from '../../Component/A';
import { Image } from "../../Component/Image";
import { H1 } from '../../Component/H1';
import { ForgetPasswordForm } from '../../Forms/ForgetPasswordForm';
import useConfig from '../../Hooks/useConfig';

const ForgetPasswordPage: React.FC = () => {
    const config = useConfig("image");
    return (
        <LoginBody>
            <NavBar>
                <LoginBox>
                    <A href='/'>
                        <Image src={process.env.PUBLIC_URL + config.logo} />
                    </A>
                </LoginBox>
            </NavBar>
            <H1>臨床教育 E-Portfolio 2.0版</H1>
            <ForgetPasswordForm />
        </LoginBody>
    )
}

export default ForgetPasswordPage;