import styled from "@mui/system/styled";
import { IconButton as MuiIconButton } from "@mui/material";

export const IconButton = styled(MuiIconButton)(({ theme }) =>
  theme.unstable_sx({
    display: "inline-block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    padding: "0 .75rem",
    borderRadius: ".25rem",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  })
);
