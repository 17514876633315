//@ts-nocheck
import { styled } from "@mui/system";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion {...props} />
))(({ theme }) =>
    theme.unstable_sx({
        background: "#F9F9F9",
        mt: 1,
        mb: 1
    })
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    />
))(({ theme }) =>
    theme.unstable_sx({
        color: window.style.primary.main
    })
);


export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) =>
    theme.unstable_sx({

    })
);