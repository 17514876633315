import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface InstallPWAProps {
    open: boolean,
    handleClose(): any,
    handleIOSInstallDeclined(): any
}

export const InstallPWA = (props: InstallPWAProps) => {
    const { open, handleClose, handleIOSInstallDeclined } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {"Install App"}
            </DialogTitle>
            <DialogContent>
                Tap
                <FileUploadOutlinedIcon fontSize="large" />
                then &quot;Add to Home Screen&quot;
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleIOSInstallDeclined();
                        handleClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}