// @ts-nocheck

const useConfig = (config: "style" | "image" | "api") => {
    switch (config) {
        case "style":
            return window.style;
        case "image":
            return window.image;
        case "api":
            return window.api;
    }
}

export default useConfig;