import { Box as MuiBox } from "@mui/material";
import { styled } from "@mui/system";

export const LoginBox = styled(MuiBox)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center!important',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto'
});