import { styled } from "@mui/system";
import { Grid, ListItemButton, ListItemText, ListItem as MuiListItem, Typography } from "@mui/material";
import useConfig from "../../Hooks/useConfig";

const ListItem = styled(MuiListItem)(({ theme }) =>
    theme.unstable_sx({
    })
);

interface ListItemProps {
    form_props: {
        form_name: string,
        start_date: string,
        end_date: string,
        group_name: string,
        share_type: string,
        form_type: string,
        assessment_user: string,
        ai_id: string
    }
}

export const PendingListItem = (props: ListItemProps) => {
    const { form_name, start_date, end_date, group_name, share_type, form_type, assessment_user, ai_id } = props.form_props;
    const style = useConfig("style");

    const handleOnClick = () => {
        window.location.href = `/pending/${ai_id}`;
    }
    const shareTypeChange = () => {
        switch (share_type) {
            case "0":
                return style.text.primary
            case "1":
                return style.success.main
            case "2":
                return style.warning.main
        }
    }

    return (
        <ListItem>
            <ListItemButton onClick={handleOnClick}>
                <ListItemText
                    primary={
                        <Typography
                            color={shareTypeChange()}
                            sx={{
                                display: "inline"
                            }}
                            variant="h5"
                        >
                            {form_name}
                        </Typography>
                    }
                    secondary={
                        <>
                            <Typography
                                sx={{
                                    display: "inline"
                                }}
                                component="span"
                                variant="body2"
                            >
                                發送單位： {group_name}
                            </Typography>
                            <br />
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="primary"
                            >
                                受評者：{assessment_user}
                            </Typography>
                            <br />
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                            >
                                {start_date} ~ {end_date}
                            </Typography>
                        </>
                    }
                />
            </ListItemButton>
        </ListItem>
    )
}

export default ListItem;