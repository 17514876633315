//@ts-nocheck
import ForgetPasswordPage from './stories/Pages/ForgetPasswordPage';
import LayoutPage from './stories/Pages/LayoutPage';
import LoginPage from './stories/Pages/LoginPage';
import PendingPage from './stories/Pages/PendingPage';
import PendingFormPage from './stories/Pages/PendingFormPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useConfig from './stories/Hooks/useConfig';
import HomePage from './stories/Pages/HomePage';
import { useEffect, useState } from 'react';
import CalendarPage from './stories/Pages/CalendarPage';
import TodayCoursePage from './stories/Pages/TodayCoursePage';
import TodayCourseDashboardPage from './stories/Pages/TodayCourseDashboardPage';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const AppRouter = () => {
    const style = useConfig('style');

    const theme = createTheme({
        palette: {
            primary: style.primary,
            secondary: style.secondary,
            success: style.success,
            info: style.info,
            warning: style.warning,
            error: style.error,
            text: style.text,
        },
    });

    const firebaseConfig = {
        apiKey: "AIzaSyAD7fV2ZXpRrpYccGFKwYtfEVWJamnSWiw",
        authDomain: "e-portfolio-9e4b9.firebaseapp.com",
        projectId: "e-portfolio-9e4b9",
        storageBucket: "e-portfolio-9e4b9.appspot.com",
        messagingSenderId: "1076081324355",
        appId: "1:1076081324355:web:ab68d1cc162b39cc1e7ff6",
        measurementId: "G-YKVH7281JP"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    // useEffect(() => {
    //     document.getElementById("root")?.addEventListener('touchstart', function (e) {
    //         if ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    //             || /Macintosh/i.test(navigator.userAgent)
    //             || /iPad|iPhone|iPod/.test(navigator.userAgent)
    //             || /iPad/i.test(navigator.userAgent)
    //         ) {
    //             if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
    //             e.preventDefault();
    //         }
    //     });
    // }, [])

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route element={<LayoutPage />} >
                        <Route index path='/' element={<HomePage />} />
                        <Route path='/pending' element={<PendingPage />} />
                        <Route path='/pending/:aiID' element={<PendingFormPage />} />
                        <Route path='/calendar' element={<CalendarPage />} />
                        <Route path='/todycourse' element={<TodayCoursePage />} />
                        <Route path='/todycourse/:courseID' element={<TodayCourseDashboardPage />} />
                    </Route>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forget" element={<ForgetPasswordPage />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default AppRouter;