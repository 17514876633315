import { ComponentStory, ComponentMeta } from "@storybook/react";

import { useState } from "react";

import { FormInput } from ".";

import { IconButton, InputAdornment } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

export default {
  title: "Forms/FormInput",
  component: FormInput,
} as ComponentMeta<typeof FormInput>;

const Template: ComponentStory<typeof FormInput> = (args) => (
  <FormInput {...args} />
);

export const UserInput: ComponentStory<typeof FormInput> = (args) => {
  return (
    <FormInput
      {...args}
      id="user"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonIcon />
          </InputAdornment>
        ),
        placeholder: " 請輸入帳號"
      }}
      sx={{
        marginBottom: '1rem!important'
      }}
    />
  )
};

export const PasswordInput: ComponentStory<typeof FormInput> = (args) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormInput
      {...args}
      id="password"
      fullWidth
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        placeholder: " 請輸入密碼"
      }}
      sx={{
        marginBottom: '1rem!important'
      }}
    />
  )
};

export const EmailInput: ComponentStory<typeof FormInput> = (args) => {
  return (
    <FormInput
      {...args}
      id="Email"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon />
          </InputAdornment>
        ),
        placeholder: " 請輸入E-Mail"
      }}
      sx={{
        marginBottom: '1rem!important'
      }}
    />
  )
};