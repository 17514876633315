//@ts-nocheck
import { PendingListItem } from "../../Component/ListItem";
import SpeedDial from "../../Component/SpeedDial";
import { FilterDialog } from "../../Component/Dialog";
import { useEffect, useState } from "react";
import { Divider, List, Container, Box, Toolbar, CircularProgress, Typography } from "@mui/material";
import { FilterIcon } from "../../Icon/FilterIcon";
import { CachedIcon } from "../../Icon/CachedIcon";
import axios from 'axios';
import Fuse from 'fuse.js';
import useConfig from "../../Hooks/useConfig";

const PendingPage: React.FC = () => {
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterListData, setFilterListData] = useState([]);
    const api = useConfig('api');

    const getData = async () => {
        const url = api + "admin/complex/quick_links/get_side_list_form";
        setListData([]);
        setLoading(true);
        const response = await axios({
            method: 'post',
            url: url,
            withCredentials: true
        })
        setListData(response.data);
        setLoading(false);
    }

    const handleFilterOpen = () => {
        setFilterOpen(true);
    }

    const handleFilterClose = () => {
        setFilterOpen(false);
    }

    useEffect(() => {
        getData();
    }, []);

    const actions = [
        {
            icon: <FilterIcon />,
            name: '篩選',
            click: () => handleFilterOpen()
        },
        {
            icon: <CachedIcon />,
            name: '重新整理',
            click: () => getData()
        },
    ];

    const options = { keys: ['form_name', 'group_name', 'assessment_user'], includeMatches: true };
    const fuseIndex = Fuse.createIndex(options.keys, listData);
    const fuse = new Fuse(listData, options, fuseIndex);

    const fliterSearch = (filterPattern) => {
        if (filterPattern.length === 0) {
            setFilterListData([]);
            return;
        }
        const list = fuse.search(filterPattern);
        setFilterListData(list);
        return;
    }

    return (
        <>
            <Toolbar sx={{ padding: '0.5rem 0px' }} />
            <Container>
                {
                    loading ?
                        <Box sx={{ textAlign: "center", paddingTop: '1rem' }}>
                            <CircularProgress />
                        </Box>
                        : null
                }
                {
                    listData.length === 0 && !loading ?
                        <Box sx={{ marginTop: '40vh', textAlign: 'center' }}>
                            <Typography variant="body2" gutterBottom >目前無表單代辦</Typography>
                        </Box>
                        : null
                }
                <List>
                    {
                        filterListData.length > 0 ?
                            filterListData.map((list) => (
                                <>
                                    <PendingListItem form_props={list.item} />
                                    <Divider component="li" />
                                </>
                            )) :
                            listData.map((item) => (
                                <>
                                    <PendingListItem form_props={item} />
                                    <Divider component="li" />
                                </>
                            ))
                    }
                </List>
            </Container >
            <SpeedDial actions={actions} />
            <FilterDialog open={filterOpen} handleDialogClose={handleFilterClose} fliterSearch={fliterSearch} />
        </>
    )
}

export default PendingPage;