//@ts-nocheck
import { Box } from "@mui/material";
import { LayoutPageAppBar } from "../../Component/AppBar";
import { useEffect, useLayoutEffect, useState } from "react";
import { ChatDialog, GetPermissionDialog, LayoutSettingDialog, NotLoginDialog, NotifyListDialog } from "../../Component/Dialog";
import { Outlet } from "react-router-dom"
import axios from "axios";
import useConfig from "../../Hooks/useConfig";
import { getMessaging, getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging";

const LayoutPage: React.FC = () => {
    const [open, seOpen] = useState<boolean>(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false);
    const [notLoginDialogOpen, setNotLoginDialogOpen] = useState<boolean>(false);
    const [getPermissionDialogOpen, setGetPermissionDialogOpen] = useState<boolean>(false);
    const [messageGroupList, setMessageGroupList] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [notifyList, setNotifyList] = useState([]);
    const [unreadNotifyCount, setUnreadNotifyCount] = useState(0);
    const api = useConfig('api');

    const get_session = async () => {
        const url = api + "login/get_session";
        const response = await axios({
            method: 'post',
            url: url,
            withCredentials: true
        })
        if (!response.data.data["user_name"])
            setNotLoginDialogOpen(true)
        else
            if ('Notification' in window) {
                if (Notification.permission != 'granted') {
                    setGetPermissionDialogOpen(true)
                }
                const messaging = getMessaging();
                const vapidKey = 'BKIRA-h_W6lbCzQaf7OhLoPXi-QagPRfR5Fm8Ji1uaIi-gLFYHYchtJ0UAInm8ZcuG9X0rC5KlzKGUSEZZorGqo'
                const currentToken = await getToken(messaging, { vapidKey: vapidKey })
                if (currentToken) {
                    // 取得 currentToken，需傳回 server
                    console.log('get currentToken');
                    console.log(currentToken);
                    const deviceTokenUrl = api + "admin/set_user_device_token";
                    const formData = new FormData();
                    formData.append("device_token", currentToken);
                    await axios({
                        method: 'post',
                        url: deviceTokenUrl,
                        data: formData,
                        withCredentials: true
                    })
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
                onMessage(messaging, (payload) => {
                    console.log('Message received. ', payload);
                    getNotifyList();
                    navigator.serviceWorker.ready.then((registration) => {
                        registration.showNotification(payload.notification.title, {
                            body: payload.notification.body,
                            icon: "/images/login-page-title.png",
                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                        });
                        navigator.setAppBadge(unreadNotifyCount);
                    });
                });
            }
    }

    const getGroupList = async () => {
        const formData = new FormData();
        const url = api + "admin/common/message_new/ajax_load_message_group_list";
        formData.append("is_pwa", 1);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        let groupList = [];
        let count = 0;
        response.data.data.group.map((item) => {
            count += item.unread
            groupList.push({
                id: item.id,
                avatar: item.sticker_url,
                title: item.group_name,
                subtitle: item.message,
                date: new Date(item.last_update * 1000),
                unread: item.unread,
            })
        })
        setUnreadMessageCount(count)
        setMessageGroupList(groupList)
    }

    const getNotifyList = async () => {
        const formData = new FormData();
        const url = api + "admin/system/notify/ajax_get_notify_list";
        formData.append("is_pwa", 1);
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            withCredentials: true
        })
        let list = [];
        let count = 0;
        response.data.data.map((item) => {
            count += (item.is_read === '0' ? 1 : 0)
            list.push(item)
        })
        setNotifyList(list)
        setUnreadNotifyCount(count)
    }

    useEffect(() => {
        get_session();
        const intervalId = setInterval(() => {
            getGroupList();
        }, 5000);
        return () => {
            clearInterval(intervalId)
            clearInterval(intervalId2)
        }
    }, []);

    const setDialogOpen = () => {
        seOpen(true);
    }

    const setDialogClose = () => {
        seOpen(false);
    }

    const setChatDialogOpen = () => {
        setChatOpen(true)
    }

    const setChatDialogClose = () => {
        setChatOpen(false)
    }

    const setNotifyDialogOpen = () => {
        setNotifyOpen(true)
    }

    const setNotifyDialogClose = () => {
        setNotifyOpen(false)
    }

    return (
        <Box>
            <LayoutPageAppBar setDialogOpen={setDialogOpen} setChatDialogOpen={setChatDialogOpen} setNotifyDialogOpen={setNotifyDialogOpen} unreadMessageCount={unreadMessageCount} unreadNotifyCount={unreadNotifyCount} />
            <Outlet />
            <LayoutSettingDialog open={open} handleDialogClose={setDialogClose} />
            <ChatDialog open={chatOpen} handleDialogClose={setChatDialogClose} messageGroupList={messageGroupList} getGroupList={getGroupList} />
            <NotifyListDialog notifyOpen={notifyOpen} handleNotifyDialogClose={setNotifyDialogClose} notifyList={notifyList} getNotifyList={getNotifyList} />
            <NotLoginDialog open={notLoginDialogOpen} />
            <GetPermissionDialog open={getPermissionDialogOpen} setGetPermissionDialogOpen={setGetPermissionDialogOpen} />
        </Box>
    )
}

export default LayoutPage;