import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Container, Snackbar, Toolbar, Typography, Chip, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { Alert } from '../../Component/Alert';
import axios from 'axios';
import useConfig from "../../Hooks/useConfig";
const TodayCourseDashboardPage = () => {
    const { courseID } = useParams();
    const [courseInfo, setCourseInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("error");
    const [alertMessages, setAlertMessages] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const api = useConfig('api');

    const getData = async () => {
        const url = `${api}admin/common/course/course_dashboard/index/${courseID}?is_pwa=1`;
        setLoading(true);
        try {
            const response = await axios({
                method: 'get',
                url: url,
                withCredentials: true
            });
            setCourseInfo(response.data.data.course_content);
            setLoading(false)
        }
        catch {
            setAlertSeverity("error");
            setAlertMessages("伺服器發生錯誤，請稍後再試。");
            setAlertOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Container fixed>
            <Toolbar sx={{ padding: '0.5rem 0px' }} />
            {
                loading ?
                    <Box sx={{ textAlign: "center", paddingTop: '1rem' }}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        <Box sx={{ background: '#F9F9F9', padding: '10px 15px', mt: 1, mb: 1 }}>
                            <Typography variant="h5">{courseInfo.course_name}</Typography>
                        </Box>
                        <Box sx={{ background: '#F9F9F9', padding: '10px 15px', mt: 1, mb: 1 }}>
                            <Chip label={courseInfo.course_mode} color="primary" />
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow
                                            key={courseInfo.year}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                學年度：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.year}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.course}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                課程代碼：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.course}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.start_time}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                開始時間：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {(new Date(courseInfo.start_time * 1000)).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.end_time}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                結束時間：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {(new Date(courseInfo.end_time * 1000)).toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.place}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                上課地點：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.place}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.course_path}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                課程類別：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.course_path}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.group_name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                開課單位：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.group_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.teacher_name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                授課教師：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.teacher_name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.assist_teachers}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                協同教師：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.assist_teachers}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.assist_auto}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                課程管理者：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.assist_auto}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.stu_num}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                學員人數：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.stu_num}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={courseInfo.course_target}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                上課對象：
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {courseInfo.course_target}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} sx={{ whiteSpace: 'pre-line' }}>
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessages}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default TodayCourseDashboardPage;